import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuListItem,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from '~/components/ui/navigation-menu'
import { Home, BookCopy, ListTodo } from 'lucide-react'
import { Button } from '~/components/ui/button'
import { Link } from '@remix-run/react'

export const PublicPageNavMenu = () => {
    return (
        <NavigationMenu className={'hidden md:block'}>
            <NavigationMenuList>
                <NavigationMenuItem>
                    <NavigationMenuLink
                        className={navigationMenuTriggerStyle()}
                        asChild
                    >
                        <Link to="/">
                            <div
                                className={
                                    'flex items-center justify-between gap-1'
                                }
                            >
                                <Home className={'h-4 w-4'} />
                                Главная
                            </div>
                        </Link>
                    </NavigationMenuLink>
                </NavigationMenuItem>

                <NavigationMenuItem>
                    <NavigationMenuTrigger>
                        <div
                            className={
                                'flex items-center justify-between gap-1'
                            }
                        >
                            <BookCopy className={'h-4 w-4'} />
                            Курсы
                        </div>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                        <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr] z-999">
                            <li className="row-span-4">
                                <NavigationMenuLink asChild>
                                    <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md gap-3">
                                        <img
                                            className="w-[300px] h-auto"
                                            src={'/schooldeskcat.png'}
                                            alt={'schooldesk'}
                                        />

                                        <p className="text-sm leading-tight text-muted-foreground">
                                            Проходи курсы вместе с кошкой
                                        </p>

                                        <Button asChild>
                                            <Link to={'/dashboard/my-courses'}>
                                                Перейти к обучению
                                            </Link>
                                        </Button>
                                    </div>
                                </NavigationMenuLink>
                            </li>
                            <NavigationMenuListItem
                                href="/courses"
                                title="Все курсы"
                            >
                                Список всех курсов, доступных на платформе.
                            </NavigationMenuListItem>
                            <NavigationMenuListItem
                                href="/programs"
                                title="Учебные программы"
                            >
                                Учебные комплексы из нескольких курсов.
                            </NavigationMenuListItem>
                            <NavigationMenuListItem href="/blog" title="Статьи">
                                Крупица знания от наших педагогов.
                            </NavigationMenuListItem>
                            <NavigationMenuListItem
                                href="/ratings/teachers"
                                title="Топ педагогов"
                            >
                                Рейтинг педагогов по отзывам учащихся.
                            </NavigationMenuListItem>
                        </ul>
                    </NavigationMenuContent>
                </NavigationMenuItem>

                <NavigationMenuItem>
                    <NavigationMenuTrigger>
                        <div
                            className={
                                'flex items-center justify-between gap-1'
                            }
                        >
                            <ListTodo className={'h-4 w-4'} />
                            Тесты
                        </div>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                        <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                            <li className="row-span-3">
                                <NavigationMenuLink asChild>
                                    <div className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-muted/50 to-muted p-6 no-underline outline-none focus:shadow-md gap-3">
                                        <img
                                            className="w-[300px] h-auto"
                                            src={'/blackboard.png'}
                                            alt={'blackboard'}
                                        />

                                        <p className="text-sm leading-tight text-muted-foreground">
                                            Решай тесты - развивай свою кошку!
                                        </p>

                                        <Button asChild>
                                            <Link to={'/dashboard/learning'}>
                                                Перейти к обучению
                                            </Link>
                                        </Button>
                                    </div>
                                </NavigationMenuLink>
                            </li>
                            <NavigationMenuListItem
                                href="/quizzes"
                                title="Открытые тесты"
                            >
                                Тесты без регистрации и СМС.
                            </NavigationMenuListItem>
                            <NavigationMenuListItem
                                href="/ratings/learners"
                                title="Топ пользователей"
                            >
                                Рекордсмены прохождения тестов.
                            </NavigationMenuListItem>
                        </ul>
                    </NavigationMenuContent>
                </NavigationMenuItem>
            </NavigationMenuList>
        </NavigationMenu>
    )
}
