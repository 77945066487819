import { PublicPageNavMenu } from './public-page-nav-menu'
import { PublicSearchWidget } from './public-search-widget'
import { PublicMobileNavMenu } from './public-mobile-nav-menu'
import { Button } from '~/components/ui/button'
import { Link, Outlet, useLoaderData, json } from '@remix-run/react'
import { TContext } from '~/lib/types'
import { LoaderFunctionArgs } from '@remix-run/node'

export async function loader(args: LoaderFunctionArgs) {
    const context = args.context as TContext
    return json({ isAuthenticated: context.accessToken })
}

export default function PublicPageLayout() {
    const loaderData = useLoaderData<typeof loader>()

    return (
        <div className="flex min-h-screen w-full flex-col">
            <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6">
                <img
                    className={'hidden md:block w-[120px] h-[50px]'}
                    src={'/biglogo-landing.png'}
                    alt={'logo'}
                />
                <PublicPageNavMenu />
                <PublicMobileNavMenu />
                <div className="flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4">
                    <div className={'m-auto flex-1 md:hidden'}>
                        <img
                            className="w-[120px] h-[50px]"
                            src={'/biglogo-landing.png'}
                            alt={'logo'}
                        />
                    </div>
                    <PublicSearchWidget />

                    <Button asChild>
                        {loaderData.isAuthenticated ? (
                            <Link to={'/dashboard'}>Личный кабинет</Link>
                        ) : (
                            <Link to={'/auth/sign-in'}>Войти</Link>
                        )}
                    </Button>
                </div>
            </header>
            <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
                <Outlet />
            </main>
        </div>
    )
}
