import { Button } from '~/components/ui/button'
import { Menu } from 'lucide-react'
import { Sheet, SheetContent, SheetTrigger } from '~/components/ui/sheet'
import { Link } from '@remix-run/react'

export const PublicMobileNavMenu = () => {
    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    variant="outline"
                    size="icon"
                    className="shrink-0 md:hidden"
                >
                    <Menu className="h-5 w-5" />
                    <span className="sr-only">Меню</span>
                </Button>
            </SheetTrigger>
            <SheetContent side="left">
                <nav className="grid gap-6 text-lg font-medium">
                    <Link to="/">
                        <img
                            className="w-[120px] h-[50px]"
                            src={"/biglogo-landing.png"}
                            alt={'Логотип Экзамо'}
                        />
                    </Link>
                    <Link
                        to="/courses"
                        className="text-muted-foreground hover:text-foreground flex items-center gap-2"
                    >
                        Курсы
                    </Link>
                    <Link
                        to="/programs"
                        className="text-muted-foreground hover:text-foreground flex items-center gap-2"
                    >
                        Учебные программы
                    </Link>
                    <Link
                        to="/blog"
                        className="text-muted-foreground hover:text-foreground flex items-center gap-2"
                    >
                        Статьи
                    </Link>
                    <Link
                        to="/ratings/teachers"
                        className="text-muted-foreground hover:text-foreground flex items-center gap-2"
                    >
                        Топ педагогов
                    </Link>
                    <Link
                        to="/quizzes"
                        className="text-muted-foreground hover:text-foreground flex items-center gap-2"
                    >
                        Открытые тесты
                    </Link>
                    <Link
                        to="/ratings/learners"
                        className="text-muted-foreground hover:text-foreground flex items-center gap-2"
                    >
                        Топ пользователей
                    </Link>
                </nav>
            </SheetContent>
        </Sheet>
    )
}
